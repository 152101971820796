import React from "react";
import Link from "next/link"

import setHtml from '/src/store/actions/stylesAction'

import Store from '/src/store'

import styles from './headerMenu.module.sass'


import UrlGenerator from '/src/common/helpers/urlGenerator'


export default class HeaderMenu extends React.Component {

  updateStateFromStore = () => {
    const currentState = this.getCurrentStateFromStore();
    if (this.state !== currentState) {
      this.setState(currentState);
    }
  }


  getCurrentStateFromStore() {
    return {
      shopsCats: Store.getState().shopsCats['list'],
      htmlClass: Store.getState().styles['html'],
    }
  }
  
  componentDidMount() {
    // this.searchInputRef.focus();
    this.unsubscribeStore = Store.subscribe(this.updateStateFromStore);
  }
  
  componentWillUnmount() {
    this.unsubscribeStore();
  }










  constructor(props) {
    super(props);


    this.state = {
      error: null,
      isLoaded: false,
      shopsCats: [],
      htmlClass: '',
    };

  }

  render() {

    if (this.state.htmlClass != 'hdr-menu') {
      return ""
    }

    return <div>

      <div className={styles.shadow} onClick={() => setHtml('')}>
        
      </div>

      <div className={styles.block}>

        <div className={`delete ${styles.delete}`} onClick={() => setHtml('')}></div>

        <div className={styles.inner}>


          <div className={styles.scroll}>

            <div className={`h2 ${styles.block_title}`}>
              Меню
            </div>
           

            

            <div className={styles.inner_top}>


              <Link href="/" className={styles.logo}>
                {/*<Logo />*/}
              </Link>

            </div>

            <div className={styles.inner_middle}>

              <ul className={styles.list_main}>

                
                <li>
                  <Link href={UrlGenerator()} onClick={() => setHtml('')}>
                    <span className={styles.icon}>
                      <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 9a3 3 0 0 1 3-3m-2 15h4m0-3c0-4.1 4-4.9 4-9A6 6 0 1 0 6 9c0 4 4 5 4 9h4Z"/>
                      </svg>
                    </span>
                    Магазин
                  </Link>
                </li>




                {/*{this.state.shopsCats.map(shopsCat => {
                  return <li className={`${styles.item} ${styles._sub}`} key={shopsCat.id}>
                    <Link href={UrlGenerator({cat: [shopsCat.alias]})} className={styles.link} onClick={() => setHtml('')}>
                        {shopsCat.title}
                    </Link>

                  </li>
                })}*/}


                
                
                <li>
                  <Link href="/check" onClick={() => setHtml('')}>
                    <span className={styles.icon}>
                      <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 9a3 3 0 0 1 3-3m-2 15h4m0-3c0-4.1 4-4.9 4-9A6 6 0 1 0 6 9c0 4 4 5 4 9h4Z"/>
                      </svg>
                    </span>
                    Проверка зрения
                  </Link>
                </li>
                
                <li>
                  <Link href="/promos" onClick={() => setHtml('')}>
                    <span className={styles.icon}>
                      <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.891 15.107 15.11 8.89m-5.183-.52h.01m3.089 7.254h.01M14.08 3.902a2.849 2.849 0 0 0 2.176.902 2.845 2.845 0 0 1 2.94 2.94 2.849 2.849 0 0 0 .901 2.176 2.847 2.847 0 0 1 0 4.16 2.848 2.848 0 0 0-.901 2.175 2.843 2.843 0 0 1-2.94 2.94 2.848 2.848 0 0 0-2.176.902 2.847 2.847 0 0 1-4.16 0 2.85 2.85 0 0 0-2.176-.902 2.845 2.845 0 0 1-2.94-2.94 2.848 2.848 0 0 0-.901-2.176 2.848 2.848 0 0 1 0-4.16 2.849 2.849 0 0 0 .901-2.176 2.845 2.845 0 0 1 2.941-2.94 2.849 2.849 0 0 0 2.176-.901 2.847 2.847 0 0 1 4.159 0Z"/>
                      </svg>

                    </span>
                    Акции
                  </Link>
                </li>
                
                <li>
                  <Link href="/departments" onClick={() => setHtml('')}>
                    <span className={styles.icon}>
                      <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"/>
                      </svg>

                    </span>
                    Салоны
                  </Link>
                </li>
                
                <li>
                  <Link href="/services" onClick={() => setHtml('')}>
                    <span className={styles.icon}>
                      <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 9a3 3 0 0 1 3-3m-2 15h4m0-3c0-4.1 4-4.9 4-9A6 6 0 1 0 6 9c0 4 4 5 4 9h4Z"/>
                      </svg>
                    </span>
                    Услуги
                  </Link>
                </li>
                
                <li>
                  <Link href="/news" onClick={() => setHtml('')}>
                    <span className={styles.icon}>
                      <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7h1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h11.5M7 14h6m-6 3h6m0-10h.5m-.5 3h.5M7 7h3v3H7V7Z"/>
                      </svg>
                    </span>
                    Новости
                  </Link>
                </li>

              </ul>

            
        


            </div>

            <div className={styles.holder}></div>

            <div className={styles.inner_bottom}>
                
                  {/*<ul className={styles.list_contacts}>
                      <li>
                          <a className={styles.phone} href="tel:+79998887766">+7 (999) 888-77-66</a>
                      </li>
                      <li>
                          <a className={styles.email} href="mailto:asd@asd.ru">web@apish.ru</a>
                      </li>
                  </ul>*/}
                  
                  <ul className={styles.list_additional}>

                      <li>
                        <Link className={styles.link} href="/pages/accept">Пользовательское соглашение</Link>
                      </li>

                      <li>
                        <Link className={styles.link} href="/pages/politic">Политика конфиденциальности</Link>
                      </li>
                  </ul>

            </div>

          </div>

        </div>

      </div>

    </div>


  }
}




