import React from "react";
import Link from "next/link"

import Store from '/src/store'

import styles from './footer.module.sass'

import UrlGenerator from '/src/common/helpers/urlGenerator'


import FooterMenu from '/src/layouts/blocks/footer/menu'
import FooterBottom from '/src/layouts/blocks/footer/bottom'

export default class Footer extends React.Component {

  updateStateFromStore = () => {
    const currentState = this.getCurrentStateFromStore();
    if (this.state !== currentState) {
      this.setState(currentState);
    }
  }

  getCurrentStateFromStore() {

    let projectName = Store.getState()['settings']['list'].find(item => item.alias == 'project_name')
    projectName = typeof projectName == 'undefined' ? '' : projectName.value


    let socialVkLink = Store.getState()['settings']['list'].find(item => item.alias == 'social_vk_link')
    socialVkLink = typeof socialVkLink == 'undefined' ? '' : socialVkLink.value

    let socialYmLink = Store.getState()['settings']['list'].find(item => item.alias == 'social_ym_link')
    socialYmLink = typeof socialYmLink == 'undefined' ? '' : socialYmLink.value

    let socialOzLink = Store.getState()['settings']['list'].find(item => item.alias == 'social_oz_link')
    socialOzLink = typeof socialOzLink == 'undefined' ? '' : socialOzLink.value

    let socialWbLink = Store.getState()['settings']['list'].find(item => item.alias == 'social_wb_link')
    socialWbLink = typeof socialWbLink == 'undefined' ? '' : socialWbLink.value

    let socialTgLink = Store.getState()['settings']['list'].find(item => item.alias == 'social_tg_link')
    socialTgLink = typeof socialTgLink == 'undefined' ? '' : socialTgLink.value
    


    return {
      shopsCats: Store.getState()['shopsCats']['list'],
      projectName: projectName,

      socialVkLink: socialVkLink,
      socialYmLink: socialYmLink,
      socialOzLink: socialOzLink,
      socialWbLink: socialWbLink,
      socialTgLink: socialTgLink,
    }
  }
  
  componentDidMount() {
    // this.searchInputRef.focus();
    this.unsubscribeStore = Store.subscribe(this.updateStateFromStore);
  }
  
  componentWillUnmount() {
    this.unsubscribeStore();
  }






  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      shopsCats: [],

      projectName: '',

      socialVkLink: '',
      socialYmLink: '',
      socialOzLink: '',
      socialWbLink: '',
      socialTgLink: '',
    };

  }
  render() {

    return <div className={`${styles.block} ${this.props.type == 'dark' ? styles.dark : ''}`}>
      <FooterMenu />
      <FooterBottom />
    </div>

  }
}

