import stylesConstants from "../constants/stylesConstants";
import Store from '/src/store'

const setHtml = (value) => {

  const currentClass = typeof document != "undefined" ? document.documentElement.getAttribute('class') : ''

  if (value.length > 0 && value != currentClass) {
    if (typeof document != "undefined") {
      document.documentElement.setAttribute('class', 'overflow '+value)
    }
    
  }
  else if (value.length == 0) {
    if (typeof document != "undefined") {
      document.documentElement.removeAttribute('class')
    }

  }
  else {
    if (typeof document != "undefined") {
      document.documentElement.removeAttribute('class')
    }
  }
  
  return Store.dispatch({
    type: stylesConstants.SET_HTML,
    payload: value,
  });
}

export default setHtml