import { createStore, combineReducers } from 'redux';

import settingsReducer from './reducers/settingsReducer';

import shopsCartReducer from './reducers/shopsCartReducer';
import shopsCatsReducer from './reducers/shopsCatsReducer';

import shopsShippingsReducer from './reducers/shopsShippingsReducer';
import shopsPaymentsReducer from './reducers/shopsPaymentsReducer';

import usersReducer from './reducers/usersReducer';
import stylesReducer from './reducers/stylesReducer';
 
const Reducers = combineReducers({
    settings: settingsReducer,

    shopsCart: shopsCartReducer,
    shopsCats: shopsCatsReducer,

    shopsShippings: shopsShippingsReducer,
    shopsPayments: shopsPaymentsReducer,

    users: usersReducer,
    styles: stylesReducer,
})
 
const Store = createStore(Reducers)

export default Store
