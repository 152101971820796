const initialState = {
    settings: {
        list: []
    },
    shops: {
        cart: {
            list: [],
        },
        shippings: {
            list: [],
        },
        payments: {
            list: [],
        },
        cats: {
            list: [],
        }
    },
    users: {
    },
    styles: {
        html: '',
    },
}
 
export default initialState;