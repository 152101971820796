import InputMask from 'react-input-mask';

export const Input = (params) => {

    const id = params.id ?? 'shopsorders-'+params.name
    const placeholder = ' '
    // const placeholder = params.placeholder ?? ' '

    if (params.type == 'tel') {
    
      return <div className={`field-block ${params.hasError ? '_error' : ''}`}>
        <label htmlFor={id}>
          {params.label}
        </label>
        <div className="field">
          <InputMask
            mask='+7 (999) 999-99-99' 
            value={params.value} 
            type={params.type ?? 'text'}
            id={id} 
            onChange={params.onChange} 
            data-field={params.name} 
            placeholder={placeholder} />
        </div>
        {params.hasError && <div className="error">{params.hasError}</div>}
      </div>
    }

    return <div className={`field-block ${params.hasError ? '_error' : ''}`}>
      <label htmlFor={id}>
        {params.label}
      </label>
      <div className="field">
        <input
          value={params.value} 
          type={params.type ?? 'text'}
          id={id} 
          onChange={params.onChange} 
          data-field={params.name} 
          placeholder={placeholder} />
      </div>
      {params.hasError && <div className="error">{params.hasError}</div>}
    </div>

};

export const Textarea = (params) => {

    const id = params.id ?? 'shopsorders-'+params.name
    const placeholder = params.placeholder ?? ''

    return <div className={`field-block ${params.hasError ? '_error' : ''}`}>
      <label htmlFor={id}>
        {params.label}
      </label>
      <div className="field">
        <textarea
          value={params.value} 
          type={params.type ?? 'text'}
          id={id} 
          onChange={params.onChange} 
          data-field={params.name} 
          placeholder={placeholder} />
      </div>
      {params.hasError && <div className="error">{params.hasError}</div>}
    </div>

};

export const Radio = (params) => {

    const id = params.id ?? 'shopsorders-'+params.name

    return <div>
      <div className={`radio-block form-group ${params.hasError ? 'has-error' : ''}`}>
        <input 
          value={params.value} 
          type="radio" 
          id={id} 
          onChange={params.onChange} 
          data-field={params.name}
          checked={params.checked ?? false} />
        <label htmlFor={id}>
          {params.label}
        </label>
      </div>
      {params.hasError && <div className="error">{params.hasError}</div>}
    </div>

};

export const Select = (params) => {

    const id = params.id ?? 'shopsorders-'+params.name
    const values = params.values ?? []


    return <div className={`field-block ${params.hasError ? '_error' : ''}`}>
      <label htmlFor={id}>
        {params.label}
      </label>
      <div className="field">
        <div className="icon"></div>
        <select
          id={id} 
          onChange={params.onChange} 
          data-field={params.name}
        >
          <option value="">Выберите</option>

          {values.map((value, key) => {
            return <option value={value.value} key={key}>{value.label}</option>
          })}

        </select>
      </div>
      {params.hasError && <div className="error">{params.hasError}</div>}
    </div>

};

export const Checkbox = (params) => {

    const id = params.id ?? 'shopsorders-'+params.name
    const checked = params.checked ?? false

    return <div className={`field-block _checkbox ${params.hasError ? '_error' : ''}`}>
      <div className="field">
        <input
          value={params.value} 
          type="checkbox"
          id={id} 
          onChange={params.onChange} 
          data-field={params.name}
          checked={checked} />
        <label htmlFor={id}>
          {params.label}
        </label>
      </div>
      {params.hasError && <div className="error">{params.hasError}</div>}
    </div>

};
