const apiUrl = 'https://api.niceyes.ru'

export const Get = (url, params = {}, headers = {}, errorFunc) => {
  if (params.id) {
    url += '/'+params.id
    delete params.id
  }
  
  return fetchApi(url, params, 'get', headers, errorFunc);
}

export const Post = (url, params = {}, headers = {}) => {
  if (params.id) {
    url += '/'+params.id
    delete params.id
  }
  
  return fetchApi(url, params, 'post', headers);
}

export const Put = (url, params = {}, headers = {}, errorFunc) => {
  if (params.id) {
    url += '/'+params.id
    delete params.id
  }

  return fetchApi(url, params, 'put', headers, errorFunc);
}

export const Delete = (url, params = {}, headers = {}) => {
  if (params.id) {
    url += '/'+params.id
    delete params.id
  }

  return fetchApi(url, params, 'delete', headers);
}




export const fetchApi = (url, params = {}, method, headers = {}, errorFunc) => {

  headers['Api-Agent'] = 'niceyes'

  let requestOptions = {
    method: method,
    headers: headers,
  };

  let getParamsString = ''

  if (JSON.stringify(params) != '{}' && (requestOptions.method == 'post' || requestOptions.method == 'put')) {

    let formData = new FormData();
    let imagesData = params.images

    delete params.images

    if (typeof imagesData != "undefined") {

      Object.keys(imagesData).map((type) => {

        if (imagesData[type] instanceof File) {

          formData.append('images['+type+'][]', imagesData[type], type)

        } else if (imagesData[type] instanceof Array) {
          imagesData[type].forEach(function(image, key) {
            formData.append('images['+type+']['+key+']', image, image.name)
          });
        }


      })

    }

    formData.append('data', JSON.stringify(params));
    requestOptions['body'] = formData

  } else if (JSON.stringify(params) != '{}' && requestOptions.method == 'get') {

    let getParams = [];

    Object.keys(params).forEach(key => {
      getParams.push(key+"="+params[key])
    })

    getParamsString = '?'+getParams.join('&')

  }

  // if (cacheKey) {
  //   if (typeof window !== "undefined" && localStorage.getItem(cacheKey) != null) {

  //     return new Promise(function(resolve, reject) {
  //       resolve(JSON.parse(localStorage.getItem(cacheKey)))
  //     })

  //   }
  // }


  return fetch(apiUrl+url+getParamsString, requestOptions)
    .then(response => {

      if (response.ok) {
        return response.json()
      } else {
        if (response.status === 403) {
          if (typeof errorFunc != 'undefined') {
            errorFunc()
          }
        }
        if (response.status === 404) throw new Error('404, Not found');
        if (response.status === 500) throw new Error('500, internal server error');
        throw new Error(response.status);
      }


      
    })
    // .then(data => data.json())
    .catch(error => {

        // console.error(error)
        // if (typeof errorFunc != 'undefined') {
        //   errorFunc()
        // }
        // console.error(error);
    });
}







// export const fetchApi = (url, params = {}, method, cacheKey) => {

//   if (typeof params == "undefined") {
//     params = {}
//   }

//   // console.log('fetchApi')

//   let requestOptions = {
//     method: method,
//     headers: {
//       'Content-Type': 'application/json',
//       'Api-Agent': 'niceyes',
//     }
//   };

//   let getParamsString = ''

//   if (JSON.stringify(params) != '{}' && (requestOptions.method == 'post' || requestOptions.method == 'put')) {
//     requestOptions['body'] = JSON.stringify(params)
//   } else if (JSON.stringify(params) != '{}' && requestOptions.method == 'get') {
//     let getParams = [];

//     Object.keys(params).forEach(key => {
//       getParams.push(key+"="+params[key])
//     })

//     getParamsString = '?'+getParams.join('&')
//   }

//   // if (cacheKey) {
//   //   if (typeof window !== "undefined" && localStorage.getItem(cacheKey) != null) {

//   //     return new Promise(function(resolve, reject) {
//   //       resolve(JSON.parse(localStorage.getItem(cacheKey)))
//   //     })

//   //   }
//   // }

//   if (typeof process.env.NEXT_PUBLIC_API_URL != "undefined") {

//     return fetch(process.env.NEXT_PUBLIC_API_URL+'/'+url+getParamsString, requestOptions)
//       .then(response => {
//         return response.json()
//       })
//       .then(result => {

//         if (typeof window !== "undefined" && cacheKey) {
//           localStorage.setItem(cacheKey, JSON.stringify(result))
//         }

//         return result;
//       })
//       .catch(e => {
//         // console.log(e);
//       });


//   }
// }