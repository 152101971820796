import React from "react";
import Link from "next/link"

import Store from '/src/store'

import styles from './footerMenu.module.sass'

import UrlGenerator from '/src/common/helpers/urlGenerator'

export default class FooterMenu extends React.Component {

  updateStateFromStore = () => {
    const currentState = this.getCurrentStateFromStore();
    if (this.state !== currentState) {
      this.setState(currentState);
    }
  }

  getCurrentStateFromStore() {

    let projectName = Store.getState()['settings']['list'].find(item => item.alias == 'project_name')
    projectName = typeof projectName == 'undefined' ? '' : projectName.value


    let socialVkLink = Store.getState()['settings']['list'].find(item => item.alias == 'social_vk_link')
    socialVkLink = typeof socialVkLink == 'undefined' ? '' : socialVkLink.value

    let socialYmLink = Store.getState()['settings']['list'].find(item => item.alias == 'social_ym_link')
    socialYmLink = typeof socialYmLink == 'undefined' ? '' : socialYmLink.value

    let socialOzLink = Store.getState()['settings']['list'].find(item => item.alias == 'social_oz_link')
    socialOzLink = typeof socialOzLink == 'undefined' ? '' : socialOzLink.value

    let socialWbLink = Store.getState()['settings']['list'].find(item => item.alias == 'social_wb_link')
    socialWbLink = typeof socialWbLink == 'undefined' ? '' : socialWbLink.value

    let socialTgLink = Store.getState()['settings']['list'].find(item => item.alias == 'social_tg_link')
    socialTgLink = typeof socialTgLink == 'undefined' ? '' : socialTgLink.value
    


    return {
      shopsCats: Store.getState()['shopsCats']['list'],
      projectName: projectName,

      socialVkLink: socialVkLink,
      socialYmLink: socialYmLink,
      socialOzLink: socialOzLink,
      socialWbLink: socialWbLink,
      socialTgLink: socialTgLink,
    }
  }
  
  componentDidMount() {
    // this.searchInputRef.focus();
    this.unsubscribeStore = Store.subscribe(this.updateStateFromStore);
  }
  
  componentWillUnmount() {
    this.unsubscribeStore();
  }






  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      shopsCats: [],

      projectName: '',

      socialVkLink: '',
      socialYmLink: '',
      socialOzLink: '',
      socialWbLink: '',
      socialTgLink: '',
    };

  }
  render() {

    return <div className={styles.menu}>

      <div className="row">

        <div className="col-md-4">

          <div className={styles.menu_block}>

            <div className={styles.logo}>
              <Link href="/">
                {this.state.projectName}
              </Link>
            </div>

            <ul>
              <li>
                <a href="/pages/accept">Пользовательское соглашение</a>
              </li>

              <li>
                <a href="/pages/politic">Политика конфиденциальности</a>
              </li>
            </ul>

            <div className={styles.marketplaces}>
              
              {this.state.socialVkLink && <Link href={this.state.socialVkLink} target="_blank">
                <img src="/template/marketplaces/vk.svg" />
              </Link>}

              {this.state.socialYmLink && <Link href={this.state.socialYmLink} target="_blank">
                <img src="/template/marketplaces/yandex.svg" />
              </Link>}

              {this.state.socialOzLink && <Link href={this.state.socialOzLink} target="_blank">
                <img src="/template/marketplaces/ozon.svg" />
              </Link>}

              {this.state.socialWbLink && <Link href={this.state.socialWbLink} target="_blank">
                <img src="/template/marketplaces/wb.svg" />
              </Link>}
              
            </div>

          </div>

        </div>

        <div className="col-md-3 col-xs-6">

          <div className={styles.menu_block}>

            {/*<div className={styles.address}>
              ООО «ЗАЙДИ-УВИДИШЬ» Юридический адрес: 191002, Россия, г. Санкт-Петербург Владимирский пр. д. 13/9, литер А, пом. 16-Н
            </div>*/}

            <div className={styles.title}>Каталог товаров</div>

            <ul>

              {this.state.shopsCats.map((shopsCat, key) => {
                return <li key={`shopsCats_${key}`}>
                  <Link href={UrlGenerator({cat: shopsCat.alias})}>
                    {shopsCat.title}
                  </Link>
                </li>
                
              })}

            </ul>

          </div>


        </div>



        <div className="col-md-5">
          <div className="row">
            <div className="col-xs-6">

              <div className={styles.menu_block}>

                <div className={styles.title}>Покупателям</div>

                <ul>
                  
                  <li>
                    <Link href="/promos">
                      О компании
                    </Link>
                  </li>
                  
                  <li>
                    <Link href="/promos">
                      Доставка
                    </Link>
                  </li>
                  
                  <li>
                    <Link href="/promos">
                      Оплата
                    </Link>
                  </li>
                    <li>
                      <Link href="/promos">
                          Акции
                      </Link>
                    </li>
                    
                    <li>
                      <Link href="/news">
                          Новости
                      </Link>
                    </li>
                    
                </ul>

              </div>

            </div>

            <div className="col-xs-6">


              <div className={styles.menu_block}>

                <div className={styles.title}>Салоны оптики</div>
                <ul>

                  <li>
                    <Link href="/promos">
                        Акции
                    </Link>
                  </li>
                  
                  <li>
                    <Link href="/news">
                      Новости
                    </Link>
                  </li>
                  
                  <li>
                    <Link href="/departments">
                      Салоны
                    </Link>
                  </li>

                  <li>
                    <Link href="/services">
                      Услуги
                    </Link>
                  </li>
                  

                </ul>

              </div>



            </div>
          </div>
        </div>
        



{/*
        <div className="col-md-3">

          <ul className="social">
            {this.state.socialVkLink && <li>
              <a href={this.state.socialVkLink} target="_blank"><i className="icofont-vk"></i></a>
            </li>}
            {this.state.socialOzLink && <li>
              <a href={this.state.socialOzLink} target="_blank"><i className="icofont-facebook"></i></a>
            </li>}
            {this.state.socialYmLink && <li>
              <a href={this.state.socialYmLink} target="_blank"><i className="icofont-instagram"></i></a>
            </li>}
            {this.state.socialTgLink && <li>
              <a href={this.state.socialTgLink} target="_blank"><i className="icofont-telegram"></i></a>
            </li>}
            {this.state.socialWbLink && <li>
              <a href={this.state.socialWbLink} target="_blank"><i className="icofont-twitter"></i></a>
            </li>}

          </ul>

        </div>
*/}


          

      </div>



    </div>


  }
}

