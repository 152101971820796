import React from "react";

import styles from './cookie.module.sass'


export default class Cookie extends React.Component {

  render() {

    return <div className={styles.block}>

      <div className={styles.text}>
        Мы обрабатываем файлы cookie, чтобы улучшить работу сайта. Оставаясь на нашем сайте, вы соглашаетесь с <a href="#">Политикой пользовательских данных</a>. Если вы хотите запретить обработку файлов cookie, отключите cookie в настройках вашего браузера
      </div>

      <div className={`delete ${styles.delete}`}>
        
      </div>

    </div>


  }
}




