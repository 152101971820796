import React, { Component, Fragment } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import setHtml from '/src/store/actions/stylesAction'

import Store from '/src/store'

import { Put, Post } from '/src/common/components/api'

import styles from './modal.module.sass'

import { Input } from '/src/common/form'

import { setAuth, getAuthHeaders } from '/src/store/actions/usersAction'



class Modal extends React.Component {

  updateStateFromStore = () => {
    const currentState = this.getCurrentStateFromStore();
    if (this.state !== currentState) {
      this.setState(currentState);
    }
  }


  getCurrentStateFromStore() {
    return {
      htmlClass: Store.getState().styles['html'],
    }
  }
  
  componentDidMount() {
    // this.searchInputRef.focus();
    this.unsubscribeStore = Store.subscribe(this.updateStateFromStore);
  }
  
  componentWillUnmount() {
    this.unsubscribeStore();
  }




  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.handleRegisterSubmit = this.handleRegisterSubmit.bind(this);

    this.state = {
    	htmlClass: '',

    	loginUsername: '',
    	loginPassword: '',
    	loginIsLoading: false,
    	loginError: '',

    	registerUsername: '',
    	registerPassword: '',
    	registerPasswordRepeat: '',
    	registerIsLoading: false,
    	registerError: '',



      formFields: {
        loginUsername: {
          label: 'Email',
          rules: {
            required: true,
            lengthMin: 2,
            lengthMax: 32
          },
        },
        loginPassword: {
          label: 'Пароль',
          rules: {
            required: true,
            lengthMin: 2,
            lengthMax: 32
          },
        },



        registerUsername: {
          label: 'Email',
          rules: {
            required: true,
            lengthMin: 2,
            lengthMax: 32
          },
        },
        registerPassword: {
          label: 'Пароль',
          rules: {
            required: true,
            lengthMin: 2,
            lengthMax: 32
          },
        },
        registerPasswordRepeat: {
          label: 'Пароль',
          rules: {
            required: true,
            lengthMin: 2,
            lengthMax: 32
          },
        },

      },

      formValues: {
      },

      formErrors: {},

    };
  }

  handleChange(event) {

  	let formValues = this.state.formValues
  	formValues[event.target.dataset.field] = event.target.value

    this.setState({
    	formValues: formValues
    });
  }

  handleLoginSubmit(event) {
    event.preventDefault();

    this.setState({
    	loginIsLoading: true,
    	loginError: '',
    })

    const params = {
      username: this.state.formValues.loginUsername,
      password: this.state.formValues.loginPassword
    }

    Put('/users/auths', params, getAuthHeaders()).then(response => {

      setAuth(response.token)
      
      setHtml('')

	    this.setState({
	    	loginIsLoading: false,
	    	loginError: '',
	    })
    
	  })
    .catch(e => {
	    this.setState({
	    	loginIsLoading: false,
	    })
	  })

  }

  handleRegisterSubmit(event) {
    event.preventDefault();

    const params = {
      username: this.state.formValues.registerUsername,
      password: this.state.formValues.registerPassword
    }

    Post('/users/auths', params, getAuthHeaders()).then(response => {

      setAuth(response.token)

      // localStorage.setItem('users.token', response.token) 

      setHtml('')

	    this.setState({
	    	registerIsLoading: false,
	    	registerError: '',
	    })
	    
	  })
    .catch(e => {
	    this.setState({
	    	registerIsLoading: false,
	    })
	  })

  }
// <li className={`${styles.note_item} ${styles.note_item_wrapper}`}></li>

  render() {

    if (this.state.htmlClass != 'modal-auth') {
      return ""
    }

    return <div className={`${styles.modal} ${styles.modal_auth}`}>

      <div className={styles.modal_wrapper} onClick={(e) => {if(e.target === e.currentTarget){setHtml('')}}}>
  	  	<div className={styles.modal_block}>
  		    <div className={`delete ${styles.delete}`} onClick={() => setHtml('')}></div>

          <div className="row">
            <div className="col-md-6">
              
            </div>

            <div className="col-md-6">

      		    <Tabs>
      		      <TabList>
      		        <Tab>Вход</Tab>
      		        <Tab>Регистрация</Tab>
      		      </TabList>
      		      <TabPanel>
      		        <form onSubmit={this.handleLoginSubmit} className="form">

                    <Input 
                      name="loginUsername" 
                      onChange={this.handleChange} 
                      label={this.state.formFields['loginUsername'].label} 
                      hasError={this.state.formErrors['loginUsername']} 
                      value={this.state.formValues['loginUsername']} />

                    <Input 
                    	type="password"
                      name="loginPassword" 
                      onChange={this.handleChange} 
                      label={this.state.formFields['loginPassword'].label} 
                      hasError={this.state.formErrors['loginPassword']} 
                      value={this.state.formValues['loginPassword']} />

      		          {this.state.loginError != '' && <div className="error">{this.state.loginError}</div>}

      		          <button type="submit" className={this.state.loginIsLoading ? 'loading' : ''}>Войти</button>
      		        </form>
      		      </TabPanel>
      		      <TabPanel>
      		        <form onSubmit={this.handleRegisterSubmit} className="form">


                    <Input 
                      name="registerUsername" 
                      onChange={this.handleChange} 
                      label={this.state.formFields['registerUsername'].label} 
                      hasError={this.state.formErrors['registerUsername']} 
                      value={this.state.formValues['registerUsername']} />

                    <Input 
                    	type="password"
                      name="registerPassword" 
                      onChange={this.handleChange} 
                      label={this.state.formFields['registerPassword'].label} 
                      hasError={this.state.formErrors['registerPassword']} 
                      value={this.state.formValues['registerPassword']} />

                    <Input 
                    	type="password"
                      name="registerPasswordRepeat" 
                      onChange={this.handleChange} 
                      label={this.state.formFields['registerPasswordRepeat'].label} 
                      hasError={this.state.formErrors['registerPasswordRepeat']} 
                      value={this.state.formValues['registerPasswordRepeat']} />



      		          {this.state.registerError != '' && <div className="error">{this.state.registerError}</div>}

      		          <div className="form-personal-check">
      		            Нажимая кнопку &laquo;Зарегистрироваться&raquo;, вы соглашаетесь на обработку ваших персональных данных
      		          </div>
      		          <button type="submit" className={this.state.registerIsLoading ? 'loading' : ''}>Зарегистрироваться</button>
      		        </form>
      		      </TabPanel>
      		    </Tabs>

            </div>

          </div>

  		  </div>

      </div>

		</div>
  }

}

export default Modal