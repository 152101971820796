import settingsConstants from "../constants/settingsConstants"
import { Get } from '/src/common/components/api'
import Store from '/src/store'


export const setSettings = () => {

  return new Promise((resolve, reject) => {

    Get('/settings').then((result) => {
      Store.dispatch({
        type: settingsConstants.ADD_SETTINGS,
        payload: result ? result : [],
      })

      resolve();
    });

  });

}
