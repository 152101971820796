import React from "react";

const UrlGenerator = (params) => {

    let result = '/catalog'
    let getParams = {}
    let depth = 0

    // if (typeof (params) == 'string') {
    //     return result+params
    // }

    if (typeof params != 'undefined') {
        if (typeof params.item != "undefined") {
            return '/product/'+params.item.alias
        }

        if (typeof params.cat != "undefined" && params.cat.length > 0 ) {
            if ((typeof params.cat == "string" || params.cat.length == 1) && depth < 2) {
                result += '/cat_'+params.cat
            } else {
                getParams['cat'] = params.cat
            }
            depth++
        }


        if (typeof params.set != "undefined" && params.set.length > 0) {
            if ((typeof params.set == "string" || params.set.length == 1) && depth < 2) {
                result += '/set_'+params.set
            } else {
                getParams['set'] = params.set
            }
            depth++
        }

        if (typeof params.label != "undefined" && params.label.length > 0) {
            if ((typeof params.label == "string" || params.label.length == 1) && depth < 2) {
                result += '/label_'+params.label
            } else {
                getParams['label'] = params.label
            }
            depth++
        }

        if (typeof params.discount != "undefined" && params.discount != 0) {
            if (depth < 2) {
                result += '/discount'
            } else {
                getParams['discount'] = ''
            }
            depth++
        }


        if (typeof params.filter != "undefined" && Object.keys(params.filter).length > 0) {
            let filterQuant = 0
            let filterUrlArray = []

            Object.keys(params.filter).forEach(filter => {
                filterQuant += typeof params.filter[filter] == "string" ? 1 : params.filter[filter].length
                filterUrlArray.push(filter + '_' + params.filter[filter])
            })

            const filterUrl = filterUrlArray.join('_')

            if (filterQuant <= 1 && depth < 2) {
                result += "/filter_"+filterUrl
            } else {
                getParams['filter'] = filterUrl
            }
            depth++
        }

    } else {
        return '/catalog'
    }


    return result+(Object.keys(getParams).length > 0 ? '?'+(new URLSearchParams(getParams)).toString() : '')
}

export default UrlGenerator