import initialState from './../initialState';
import isEqual from 'lodash.isequal';

export default function contactReducer(state = initialState.shops.cart, action) {
    switch(action.type) {

        case "INIT_SHOPS_CART": {
            return action.payload
        }


        case "RESET_SHOPS_CART": {
            if (typeof window !== "undefined") {
                localStorage.removeItem('shops.cart')
            }
            const currentCart = typeof window !== "undefined" ? JSON.parse(localStorage.getItem('shops.cart') ?? '[]').filter(element => element !== null) : []

            return {
                ...state,
                list: currentCart
            }

        }
         
        case "ADD_SHOPS_CART_ITEM": {
            return action.payload
        }
         
        case "REMOVE_SHOPS_CART_ITEM": {
            return action.payload
        }

        default: return state;
    }
}
