import React from "react";
import Link from "next/link"

import setHtml from '/src/store/actions/stylesAction'

import Store from '/src/store'

import styles from './headerSearch.module.sass'

import { Get } from '/src/common/components/api'

import UrlGenerator from '/src/common/helpers/urlGenerator'
import PriceFormater from '/src/common/helpers/priceFormater'

import { withRouter, useRouter } from 'next/router'

class Component extends React.Component {

  updateStateFromStore = () => {
    const currentState = this.getCurrentStateFromStore();


    if (this.state !== currentState) {
      this.setState(currentState);
    }
    
  }

  getCurrentStateFromStore() {

    return {
      htmlClass: Store.getState().styles['html'],
    }
  }
  
  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.updateStateFromStore);
    // this.searchInputRef.current.focus()
  }
  
  componentWillUnmount() {
    this.unsubscribeStore();
  }

  constructor(props) {

    super(props);

    this.searchInputRef = React.createRef();
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    
    this.state = {
      error: null,
      isLoaded: false,
      shopsCats: Store.getState()['shopsCats']['list'],
      searchWord: '',
      htmlClass: '',

      searchResultsShopsItems: [],
      searchResultsShopsCats: [],
      searchResultsServices: [],
      searchResultsShopsItemsParamsValues: [],
    };

  }


  handleFocus(event) {

    // setHtml('hdr-search')

    if (this.state.htmlClass == 'hdr-search') {
      // setHtml('')
    } else {
      setHtml('hdr-search')
    }
  }


  handleSubmit(event) {

    event.preventDefault();

    if (this.state.searchResultsShopsItems.length > 0) {
      setHtml('')

      this.setState({
        searchResultsShopsItems: [],
        searchResultsShopsCats: [],
        searchResultsServices: [],
        searchResultsShopsItemsParamsValues: [],
        searchWord: '',
      })

      this.props.router.push(
        UrlGenerator({item: this.state.searchResultsShopsItems[0]}),
        undefined,
        {
          scroll: false,
        }
      )
    }




    // setHtml('hdr-search')

    // if (this.state.htmlClass == 'hdr-search') {
    //   // setHtml('')
    // } else {
    //   setHtml('hdr-search')
    // }
  }


  handleClose(event) {
    setHtml('')

    this.setState({
      searchResultsShopsItems: [],
      searchResultsShopsCats: [],
      searchResultsServices: [],
      searchResultsShopsItemsParamsValues: [],
      searchWord: '',
    })
  }

  handleChange(event) {

    const value = event.target.value

    this.setState({
      searchWord: value,
    })

    Get('/search', {query: value})
      .then(response => {
        this.setState({
          searchResultsShopsItems: response.shops_items ?? [],
          searchResultsShopsCats: response.shops_cats ?? [],
          searchResultsServices: response.services ?? [],
          searchResultsShopsItemsParamsValues: response.shops_items_params_values ?? [],
        })
        
      })
      .catch(e => {
        this.setState({
          searchResultsShopsItems: [],
          searchResultsShopsCats: [],
          searchResultsServices: [],
          searchResultsShopsItemsParamsValues: [],
        })
      })

  }

  handleClick(event) {

    this.setState({
      searchResultsShopsItems: [],
      searchResultsShopsCats: [],
      searchResultsServices: [],
      searchResultsShopsItemsParamsValues: [],
      searchWord: '',
    })

    setHtml('')

  }


  

  render() {

    return <div className={`${styles.block_outer} ${this.state.htmlClass == 'hdr-search' && styles._active}`}>

      {this.state.htmlClass == 'hdr-search' && <div className={styles.shadow} onClick={this.handleClose}></div>}
      
      <div className={styles.block}>

        <div className={`h2 ${styles.block_title}`}>
          Поиск
        </div>

        <div className={`delete ${styles.delete}`} onClick={() => setHtml('')}></div>

        <form className={`${styles.input_block} ${this.state.htmlClass == 'hdr-search' && styles._active}`} onSubmit={this.handleSubmit}>
          <input ref={this.searchInputRef} type="text" className={`${styles.input}`} placeholder="Поиск" onChange={this.handleChange} placeholder="Поиск по названиям товаров" onFocus={this.handleFocus} value={this.state.searchWord}
          />
        </form>




        {this.state.htmlClass == 'hdr-search' && <div className={styles.block_inner}>




          {this.state.searchResultsShopsItems.length == 0 
            && this.state.searchResultsServices.length == 0 
            && this.state.searchResultsShopsCats.length == 0 
            && this.state.searchResultsShopsItemsParamsValues.length == 0 
            && <div className={styles.pre_entry}>
            Вы можете искать по названию товаров
          </div>}


          {this.state.searchResultsShopsItems.length > 0 && <div>
            <div className={styles.group}>
              Товары
            </div>

            <ul>
              {this.state.searchResultsShopsItems.map((item, key) => {

                return <li className={styles.item} key={key}>
                  <Link href={UrlGenerator({item: item})} className={styles.link} onClick={this.handleClick}>
                    <span className={styles._title}>
                      {item.title}
                    </span>
                    <span className={styles._holder}>
                      
                    </span>
                    <span className={styles._price}>
                      {PriceFormater(item.price)}
                    </span>
                  </Link>
                </li>
              })}
            </ul>
          </div>}







          {this.state.searchResultsShopsCats.length > 0 && <div>
            <div className={styles.group}>
              Категории товаров
            </div>

            <ul>
              {this.state.searchResultsShopsCats.map((item, key) => {
                return <li className={styles.item} key={key}>
                  <Link href={UrlGenerator({cat: item.alias})} className={styles.link} onClick={this.handleClick}>
                    <span className={styles._title}>
                      {item.title}
                    </span>
                  </Link>
                </li>
              })}
            </ul>
          </div>}






          {this.state.searchResultsServices.length > 0 && <div>
            <div className={styles.group}>
              Услуги
            </div>

            <ul>
              {this.state.searchResultsServices.map((item, key) => {
                return <li className={styles.item} key={key}>
                  <Link href={`/services/`+item.alias} className={styles.link} onClick={this.handleClick}>
                    <span className={styles._title}>
                      {item.title}
                    </span>
                  </Link>
                </li>
              })}
            </ul>
          </div>}




          {this.state.searchResultsShopsItemsParamsValues.length > 0 && <div>
            <div className={styles.group}>
              Параметры
            </div>

            <ul>
              {this.state.searchResultsShopsItemsParamsValues.map((item, key) => {
                let urlFilter = {}
                urlFilter[item.param_alias] = [item.alias]
                return <li className={styles.item} key={key}>
                  <Link href={UrlGenerator({filter: urlFilter})} className={styles.link} onClick={this.handleClick}>
                    <span className={styles._title}>
                      {item.title}
                    </span>
                  </Link>
                </li>
              })}
            </ul>
          </div>}





        </div>}



      </div>
    </div>


  }
}






function Index({ router }) {
  if (router.isReady) {
    return <Component router={router} />
  }
}

export default withRouter(Index)
