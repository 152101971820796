import React from 'react'

import html5_reset from '/src/assets/sass/libs/html5-reset.sass'
import flexboxgrid from '/src/assets/sass/libs/flexboxgrid.sass'

import colors from '/src/assets/sass/variables/colors.sass'
import fonts from '/src/assets/sass/variables/fonts.sass'
import sizes from '/src/assets/sass/variables/sizes.sass'

import tags from '/src/assets/sass/blocks/tags.sass'
import form from '/src/assets/sass/blocks/form.sass'
import btn from '/src/assets/sass/blocks/btn.sass'

import page from '/src/assets/sass/blocks/page.sass'

// import icons from '/src/assets/sass/blocks/icons.sass'

import styles from '/src/assets/sass/styles.sass'

import MainLayout from './../layouts/MainLayout';


export default function App({ Component, pageProps }) {

  // const router = useRouter()

  // pageProps.asd = router
  // console.log('pageProps', pageProps.asd)
  // console.log('router', router)
  // const [loading, setLoading] = React.useState(false)

  // React.useEffect(() => {
  //   setTimeout(() => setLoading(true), 1000)
  // }, [])

  // console.log('loading', loading)

  // console.log('Component.getLayout', Component.getLayout)

  if (Component.Layout) {

    const Layout = Component.Layout
    
    return <Layout>
      <Component {...pageProps} />
    </Layout>
  }





  // if (Component.getLayout) {
  //   const getLayout = Component.getLayout ?? ((page) => page)
  //   return getLayout(<Component {...pageProps} />)
  // }






  return <MainLayout>
    <Component {...pageProps} />
  </MainLayout>
}

