import shopsCatsConstants from "../constants/shopsCatsConstants";
import { Get } from '/src/common/components/api'
import Store from '/src/store'


export const setShopsCats = () => {

  return new Promise((resolve, reject) => {

    Get('/shops/cats').then((result) => {

      Store.dispatch({
        type: shopsCatsConstants.ADD_SHOPS_CATS,
        payload: result ? result : [],
      })

      // console.log('cats os ready')

      resolve();
    });

  });
}
