import initialState from './../initialState';

export default function contactReducer(state = initialState.shops.payments, action) {
    switch(action.type) {
         
        case "ADD_SHOPS_PAYMENTS": {
            return {
                ...state,
                list: [...action.payload]
            }
        }

        default: return state;
    }
}