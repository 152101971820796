import initialState from './../initialState';

export default function contactReducer(state = initialState.users, action) {
    switch(action.type) {

        case "SET_TOKEN": {
            return {
                ...state,
                token: state == action.payload ? '' : action.payload
            }
        }

        case "SET_AUTH": {
            return action.payload
        }


        default: return state;
    }
}