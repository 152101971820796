import React from "react";

// A function to format text to look like a phone number
const PhoneFormater = (input) => {

  // Strip all characters from the input except digits
  input = input.toString().replace(/\D/g,'');
  
  // Trim the remaining input to ten characters, to preserve phone number format
  input = input.substring(0,11);

  // Based upon the length of the string, we add formatting as necessary
  var size = input.length;
  if(size == 0){
          input = input;
  }else if(size < 4){
          input = '('+input;
  }else if(size < 7){
          input = '('+input.substring(0,3)+') '+input.substring(3,6);
  }else{
          input = '+'+input.substring(0,1)+' ('+input.substring(1,4)+') '+input.substring(4,7)+'-'+input.substring(7,9)+'-'+input.substring(9,11);
  }

  return input; 
}

export default PhoneFormater