import initialState from './../initialState';

export default function contactReducer(state = initialState.styles, action) {
    switch(action.type) {

        case "SET_HTML": {

            return {
                ...state,
                html: state.html == action.payload ? '' : action.payload
            }
        }

        default: return state;
    }
}