import React from "react";

import styles from './priceFormater.module.sass'

const PriceFormater = (price, price_orig, show_string = true) => {
    if (price_orig > 0 && price_orig != price) {
        return <div>
            <span className={styles._dis}>{format(price, 0, ' ', ' ')} <span className="currency">₽</span></span>
            <span className={styles._old}>{format(price_orig, 0, ' ', ' ')} <span className="currency">₽</span></span>
        </div>
    }

    if (show_string && price == 0) {
        return 'Бесплатно'
    }

    return <div>{format(price, 0, ' ', ' ')} <span className="currency">₽</span></div>
}


const format = (price, decimals, dec_point, thousands_sep) => {
    price = (price + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+price) ? 0 : +price,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}


export default PriceFormater