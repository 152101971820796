import React, { Component } from "react";
import Link from "next/link"

import Store from '/src/store'

import PriceFormater from '/src/common/helpers/priceFormater'
import PhoneFormater from '/src/common/helpers/phoneFormater'
import NumberFormater from '/src/common/helpers/numberFormater'

import ImageRenderer from '/src/common/components/imageRenderer'

import setHtml from '/src/store/actions/stylesAction'


import { Post } from '/src/common/components/api'

import { Get } from '/src/common/components/api'

import { addShopsCartItem, removeShopsCartItem, resetShopsCart } from '/src/store/actions/shopsCartAction'

import styles from './headerCart.module.sass'


import UrlGenerator from '/src/common/helpers/urlGenerator'


export default class HeaderCart extends React.Component {

  updateStateFromStore = () => {
    const currentState = this.getCurrentStateFromStore();
    if (this.state !== currentState) {
      this.setState(currentState);
    }
  }

  getCurrentStateFromStore() {
    return {
      shopsCart: Store.getState().shopsCart ?? {},
      htmlClass: Store.getState().styles['html'],
    }
  }

  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.updateStateFromStore);
  }
  
  componentWillUnmount() {
    this.unsubscribeStore();
  }

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    
    this.state = {
      error: null,
      isLoaded: false,

      shopsCats: [],
      shopsCart: {},

      cartIsLoading: false,

      shiptypes: [],
      paytypes: [],

      formFields: {
        nameFirst: {
          label: 'Имя',
          rules: {
            required: true,
            lengthMin: 2,
            lengthMax: 32
          },
        },
        nameLast: {
          label: 'Фамилия',
        },
        phone: {
          label: 'Телефон',
        },
        email: {
          label: 'Email',
        },
        comment: {
          label: 'Комментарий',
        },
        payType: {
          label: 'Способ оплаты',
        },
        shipType: {
          label: 'Способ доставки',
        },
        acceptPolitics: {
          type: 'checkbox',
          label: 'Согласие с политикой конфиденциальности и защиты информации',
          rules: {
            required: true,
          }
        }
      },
      formValues: {
        // nameFirst: Store.getState()['users']['profile']['name_first'],
        // nameLast: Store.getState()['users']['profile']['name_last'],
        // phone: Store.getState()['users']['profile']['phone'],
        // email: Store.getState()['users']['profile']['username'],
        comment: '',

        // payType: this.state.paytypes[0].shops_payment_id ?? {},
        // shipType: this.state.shiptypes[0].shops_shipping_id ?? {},
        payType: "",
        shipType: "",

        acceptPolitics: false,
      },
      formErrors: {},
    };

  }












  setCartShiptypes = (shipType, params) => {

    let shiptypes = this.state.shiptypes

    {Object.keys(params).map((param, key) => {
        shiptypes[shipType][param] = params[param]
    })}



    this.setState({
      shiptypes: shiptypes,
    })

  }



  getCartAmountWithShipping() {
    return this.getCartAmountWithDiscount() + this.getCartShippingAmount()
  }

  getCartAmountWithDiscount() {
    return this.state.shopsCart.amount
  }

  getCartItemsAmount() {
    return this.getCartAmountWithDiscount() + this.getCartItemsDiscount()
  }

  getCartItemsDiscount() {
    return this.state.shopsCart.discount
  }

  getCartShippingAmount() {
    let result = 0;

    const shopsShipping = this.state.shiptypes[this.state.formValues.shipType]

    if (typeof shopsShipping == "undefined") {
      return 0
    }

    const cartItemsAmount = this.getCartAmountWithDiscount()

    let foundedParam = false

    shopsShipping.params.forEach(param => {

      if (
        cartItemsAmount > param.order_amount_from 
        && (cartItemsAmount < param.order_amount_to  || param.order_amount_to == 0)
      ) {

        if (param.shipping_unit == 0) {
          result += param.shipping_price
        } else if (param.shipping_unit == 1) {
          result += param.shipping_price * cartItemsAmount / 100 
        }

        foundedParam = true

      }

    })

    if (
      foundedParam == false
      && typeof this.state.shiptypes[this.state.formValues.shipType] != "undefined"
      && typeof this.state.shiptypes[this.state.formValues.shipType].price != "undefined"
    ) {
      result += this.state.shiptypes[this.state.formValues.shipType].price
    }

    return result

  }

  handleChange(event) {

    let formValues = this.state.formValues

    if (event.target.type == 'checkbox') {
      formValues[event.target.dataset.field] = event.target.checked
    } else {
      formValues[event.target.dataset.field] = event.target.value  
    }

    this.setState({
      formValues
    });

    this.validateForm({
      [event.target.dataset.field]: formValues[event.target.dataset.field]
    })

  }


  validateForm(params) {

    let errors = this.state.formErrors

    Object.keys(params).forEach(param => {

      delete(errors[param])

      let value = params[param]
      let validateRules = this.state.formFields[param].rules ?? []
      let type = this.state.formFields[param].type ?? ''
      let label = this.state.formFields[param].label
      
      Object.keys(validateRules).forEach(ruleKey => {

        const ruleValue = validateRules[ruleKey]

        if (
          ruleKey == 'required' 
          && (
            value == '' && type != 'checkbox'
            || value == false && type == 'checkbox'
          )
        ) {
          errors[param] = 'Укажите «'+label+'»'
        }

        if (ruleKey == 'lengthMin' && value.length < ruleValue) {
          errors[param] = 'Длина поля «'+label+'» меньше '+ruleValue+' символов'
        }

        if (ruleKey == 'lengthMax' && value.length > ruleValue) {
          errors[param] = 'Длина поля «'+label+'» больше '+ruleValue+' символов'
        }

      })

    })

    this.setState({
      formErrors: errors
    })

    if (Object.keys(errors).length != 0) {
      return true
    }

    return true

  }














  render() {

    if (this.state.htmlClass != 'hdr-cart-active' || this.state.shopsCart.quant == 0) {
      return ""
    }

    const paramsVars = {
        add: 'Аддидация',
        axis: 'Ось',
        bc: 'Кривизна',
        color: 'Цвет',
        cyl: 'Цилиндр',
        dioptry: 'Оптическая сила',
        size: 'Размер',
    }

    return <div>

      <div className={styles.shadow} onClick={() => {
        setHtml('')}
      }></div>

      
      <div className={`${styles.block} ${this.state.htmlClass == 'hdr-cart-active' ? styles.active : ''}`}>
    {/*return <div className={styles.block}>*/}


        <div className={`delete ${styles.delete}`} onClick={() => {
          setHtml('')}
        }></div>


        <div className={styles.inner}>


            <div className={styles.scroll}>

                

                <div className={`h2 ${styles.block_title}`}>
                  Корзина
                </div>

                <div className={styles.items}>

                  {this.state.shopsCart.shops_carts_items.map((item, key) => {
                              
                    return <div className={styles.item} key={key}>

                      <div className={styles.wrapper}>

                        <div className={styles.title}>

                          <Link href={UrlGenerator({item: item})} className={`${styles.item_title}`} onClick={() => setHtml('')}>
                            {item.title}
                          </Link>

                          {false && Object.keys(item.params).length != 0 && <div className={styles.shops_params}>
                            {Object.keys(item.params).map((param, key) => {

                              return <div className={styles.param} key={key}>
                                <div className={styles.title}>{paramsVars[param]}</div>
                                <div className={styles.line}></div>
                                <div className={styles.value}>{item.params[param]}</div>
                                <div className={styles.clearfix}></div>
                              </div>

                            })}
                          </div>}

                        </div>

                        <div className={styles.price}>
                          <div className={styles.price_per}>
                            {PriceFormater(item.price, item.price_old)}
                          </div>
                        </div>

                      </div>


                      <div className={styles.quant}>
                        &times;{item.quant}
                      </div>


                      
                      








                      <div className={styles.controls}>

                        <div className={`${styles.action} ${styles.action_plus}`} onClick={() => {

                          addShopsCartItem({
                            id: item.id,
                            params: item.params,
                          })

                          window.dataLayer.push({
                            "ecommerce": {
                              "currencyCode": "RUB",
                              "add" : {
                                "products": [{
                                  "id": item.id,
                                  "name" : item.title,
                                  "price": item.price,
                                  "quantity": 1
                                }]
                              }
                            }
                          });

                        }}></div> 
                        
                        <div className={styles.value}>
                          {item.quant}
                        </div>

                        <div className={`${styles.action} ${styles.action_minus}`} onClick={() => {

                          removeShopsCartItem({
                            id: item.id,
                            params: item.params,
                          })

                          window.dataLayer.push({
                            "ecommerce": {
                              "currencyCode": "RUB",
                              "remove" : {
                                "products": [{
                                  "id": item.id,
                                  "name" : item.title,
                                  "price": item.price,
                                  "quantity": 1
                                }]
                              }
                            }
                          });

                        }}></div>


                      </div>







                    </div>

                  })}

                </div>

                

                <div className={styles.holder}></div>


                {this.state.shopsCart.quant != 0 && <div className={styles.btn_block}>

                  <div className={styles.result_price}>

                      <div className="row">
                          <div className="col-xs-6">
                              <div className="h6">Итого: </div>
                          </div>

                          <div className="col-xs-6">

                              <div className={styles.results}>

                                  <span className={styles.value}>
                                      {PriceFormater(this.getCartAmountWithDiscount())}
                                  </span>

                              </div>

                          </div>
                      </div>

                  </div>

                  <Link href="/cart" className="btn shops-cart-btn">
                     Перейти к оформлению
                  </Link>

                </div>}


            </div>

        </div>

      </div>

    </div>

  }

}




