import Image from 'next/image'

// const Sizes = () => (
//     {
//         'sm' = 480,
//         'md' = 960,
//         'lg' = 1920,

//         // 'xs' => 240,
//         // 'sm' => 480,
//         // 'md' => 960,
//         // 'lg' => 1920,
//     }
// );


const ImageRenderer = (params) => {

    if (params.image.image == null) {
        return
    }

    let alt = params.alt
    let maxWidth = params.image.maxWidth
    let image = params.image
    let layout = params.layout ?? 'raw'
    let priority = params.priority ?? false

    const sizes = {
        sm: 767,
        md: 1000,
        lg: 1400,
    }

    let scrSet = []
    // let jpgExt = 'jpg'

    let src = ''

    {Object.keys(params.image.image).forEach(extension => {

        if (params.image.image[extension] != null) {

            Object.keys(params.image.image[extension]).forEach(size => {
                scrSet.push(params.image.image[extension][size]+'  '+sizes[size]+'w')

                src = params.image.image[extension][size]
            })

        }

    })}

    // {image.extensions.forEach(extension => {

    //     Object.keys(sizes).forEach(size => {

    //         if (sizes[size] > maxWidth) {
    //             return
    //         }

    //         scrSet.push(image.path+'/'+size+'_'+image.image+'.'+extension+' '+sizes[size]+'w')
    //     })

    //     if (['jpg', 'png', 'jpeg'].indexOf(extension) != -1) {
    //         jpgExt = extension
    //     }

    // })}

    return <img 
        width={image.width} 
        alt={alt} 
        height={image.height} 
        // src={image.path+'/lg_'+image.image+'.'+jpgExt} 
        // sizes="(min-width: 1023px) 780px,(min-width: 675px) 620px,100vw"
        src={src}
        srcSet={scrSet.join(',')} 
        />

};

export default ImageRenderer