import shopsCartConstants from "../constants/shopsCartConstants";
import Store from '/src/store'

import { Get, Put, Delete } from '/src/common/components/api'

import { setAuth, getAuthHeaders } from '/src/store/actions/usersAction'
import setHtml from '/src/store/actions/stylesAction'

export const initShopsCart = () => {

  return new Promise((resolve, reject) => {
    const errorFunc = function () {
      setAuth('')
    }

    Get('/shops/carts', {}, getAuthHeaders(), errorFunc)
      .then(result => {

        Store.dispatch({
          type: shopsCartConstants.INIT_SHOPS_CART,
          payload: result ? result : [],
        })

        resolve();
      })
      .catch((e) => {

      });

  });
}


export const resetShopsCart = (value) => {
  return Store.dispatch({
    type: shopsCartConstants.RESET_SHOPS_CART,
    payload: value,
  });
}


export const addShopsCartItem = (params) => {

  if (typeof params.params == 'undefined') {
      params.params = {};
  }

  const errorFunc = function () {
    setAuth('')
  }

  const headers = getAuthHeaders()
  Put('/shops/carts', params, headers, errorFunc).then((result) => {

    if (typeof result.token != 'undefuned' && result.token && headers['Auth-Token'] != result.token) {
      setAuth(result.token)
    }

    return Store.dispatch({
      type: shopsCartConstants.ADD_SHOPS_CART_ITEM,
      payload: result ? result : [],
    })
  });

}






export const removeShopsCartItem = (params) => {

  if (typeof params.params == 'undefined') {
      params.params = {};
  }

  Delete('/shops/carts', params, getAuthHeaders()).then((result) => {

    if (result == null) {
      setHtml('')
    }

    return Store.dispatch({
      type: shopsCartConstants.REMOVE_SHOPS_CART_ITEM,
      payload: result ? result : [],
    })
  });

}
