import usersConstants from "../constants/usersConstants";
import shopsCartConstants from "../constants/shopsCartConstants";

import { Get } from '/src/common/components/api'
import { initShopsCart } from '/src/store/actions/shopsCartAction'

import Store from '/src/store'

export const initAuth = () => {

  return new Promise((resolve, reject) => {
    let token = ''

    if (typeof window !== "undefined" && typeof localStorage != "undefined") {
      token = localStorage.getItem('users.token') 
    }

    setAuth(token)

    resolve();
  });

}




export const getAuthHeaders = () => {
  let auth = {
    'Auth-Token': Store.getState()['users']['token'] ?? '',
    'Auth-Id': '',
  }
  return auth
}



export const setAuth = (token) => {
  if (typeof window !== "undefined" && typeof localStorage != "undefined") {
    localStorage.setItem('users.token', token) 

    Store.dispatch({
      type: usersConstants.SET_TOKEN,
      payload: token,
    });
  }

  if (token.length == 0) {
    return
  }

  Get('/users/auths', {}, getAuthHeaders())
    .then(result => {

      return Store.dispatch({
        type: usersConstants.SET_AUTH,
        payload: result ? result : [],
      });

      resolve();
    });

  initShopsCart()
}



export const logout = () => {
  if (typeof window !== "undefined") {
    localStorage.setItem('users.token', '') 
  }

  Store.dispatch({
    type: shopsCartConstants.INIT_SHOPS_CART,
    payload: [],
  })

  Store.dispatch({
    type: usersConstants.SET_PROFILE,
    payload: {},
  })

  return Store.dispatch({
    type: usersConstants.SET_AUTH,
    payload: '',
  });
}
